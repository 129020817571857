import React from 'react';
import Layout from '../components/layout';
import Page from '../components/page';
import SEO from '../components/seo';
import Img from 'gatsby-image'
import data from '../content/pages/mission.yml';
import { Link, useStaticQuery, graphql } from 'gatsby';

const Mission = () => {
  const imgData = useStaticQuery(graphql`
    query {
      limeTree: file(relativePath: { eq: "lime-tree.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Mission"></SEO>
      <Page>
        <Page.Header title={data.title} subtitle={data.subtitle} />
        <Page.Content>
          <div className="content">
            <Img className="img-float-right" fluid={imgData.limeTree.childImageSharp.fluid} />
            {data.content.map(section => (
              <React.Fragment key={section.item}>
                <h3>{section.heading}</h3>
                <p>{section.text}</p>
                {section.link_href && (
                  <Link className="button is-primary" to={section.link_href}>
                    {section.link_text}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </div>
        </Page.Content>
      </Page>
    </Layout>
  );
};

export default Mission;
